import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
//import { AccountService, AlertService } from './../../../../_services';
import { SubscriberService } from '@app/services/subscriber.service';
import { AlertService } from '@app/services/alert.service';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '@app/services/common.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

    form: FormGroup;
    loading: boolean = false;
    submitted: boolean = false;
    typePassword: boolean = false;
    returnUrl: string = 'dashboard';
    returnUrlTrfd: string = 'transified/dashboard';
    ipAddress = '';
    browser = '';
    latitude: number = 0;
    longitude: number = 0;
    unsubscribe$ = new Subject()

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: SubscriberService,
        public commonService:CommonService,
        private alertService: AlertService,
        private http: HttpClient
    ) { }

    ngOnDestroy(): void {
        /*const element = document.querySelector(".google-translate-element");
        element.classList.remove('hide-language');*/
        this.unsubscribe$.next()
        this.unsubscribe$.complete()

      }

    ngOnInit() {
        /*const element = document.querySelector(".google-translate-element");
        element.classList.add('hide-language');*/
        const user = this.accountService.userValue;
        if(user == undefined) {
             // Perform logout
             //Navigate to login/home
          this.router.navigate(['/signin']);
        } else if(user && user.access_token) {
            if (user.userType == "trfdMgmt" || user.userType == "trfdAdmin" || user.userType == "trfdSupport") {
              this.router.navigate([this.returnUrlTrfd]);
            } else {
              this.router.navigate([this.returnUrl]);
            }
        }

        this.browser = this.detectBrowserName();
        this.getIPAddress();
        this.getLocation();
        this.form = this.formBuilder.group({
            username: ['', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';

        this.returnUrlTrfd = this.route.snapshot.queryParams['returnUrl'] || 'transified/dashboard';

        let branchData = { 'branch': "", 'showAllBranches': true };
        localStorage.setItem('userBranch', JSON.stringify(branchData));
        localStorage.setItem('showBranchPopup', "N");
    }

    getIPAddress() {
        this.http.get("http://api.ipify.org/?format=json").pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
            this.ipAddress = res.ip;
        });
    }

    detectBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }

    getLocation(): void {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.longitude = position.coords.longitude;
                this.latitude = position.coords.latitude;
            });
        } else {
            // console.log("No support for geolocation")
        }
    }

    public togglePassword(): void {
        this.typePassword = !this.typePassword;
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        localStorage.setItem('showBranchPopup', "Y");
        this.loading = true;
        this.accountService.login(this.f.username.value, this.f.password.value, this.ipAddress, this.latitude, this.longitude, this.browser).pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                data => {
                    if (data.access_token) {
                        this.commonService.getlocalName(); // set local name
                        this.commonService.getuserDetail(); // set user info
                        localStorage.setItem('showBranchPopup', data.showBranchPopup);
                        this.commonService.activeMenu('/dashboard')
                        if (data.userType == "trfdMgmt" || data.userType == "trfdAdmin" || data.userType == "trfdSupport") {
                            localStorage.setItem('showBranchPopup', "N");
                            this.router.navigate([this.returnUrlTrfd]);
                        } else {
                            this.router.navigate([this.returnUrl]);
                        }
                    }
                    else {  
                        this.alertService.error("Something went wrong, please try later.");
                        this.loading = false;
                    }
                },
                error => {  
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}
